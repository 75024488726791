<template>
  <div class="store-navigation">
    <ul>
      <li>Store Home</li>
      <li>Products</li>
      <li>Limited Time sale</li>
      <li>Topselling</li>
      <li>Review</li>
      <li>About Us</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'StoreNavigation'
}
</script>

<style lang='scss' scoped>
.store-navigation {
  height: 46px;
  line-height: 46px;
  background: #FF8223;

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style: none;
      height: 100%;
      display: inline-block;
      font-family: "PingFang SC";
      font-weight: 400;
      font-size: 14px;
      text-align: center;
      color: #fff;
      padding: 0 22px;
      cursor: pointer;
      &:hover{
        background: rgb(246,120,0);
      }
    }
  }
}
</style>
