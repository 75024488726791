<template>
  <div>
    <div class="storeBox">
      <div class="store">
        <div class="store-left">
          <div class="">
            <div class="store-logo">LOGO</div>
            <div class="store-share">
              <span>
                分享
                <a-icon type="down" class="store-share-icon" />
              </span>
              <dl class="topsharecon clearfix" id="TopShareCon">
                <dt>Share this seller's store</dt>
                <dd class="li13" >
                  <a
                    onclick="gtag('event', 'homepage', {'event_category': 'Seller store','event_label': 'Share on','value': 'facebook'});"
                    href="javascript:loadPage('facebook', title, description)"
                    rel="nofollow">Facebook</a>
                </dd>
                <dd class="li12">
                  <a
                    onclick="gtag('event', 'homepage', {'event_category': 'Seller store','event_label': 'Share on','value': 'twitter'});"
                    href="javascript:loadPage('twitter', title, description)"
                    rel="nofollow">Twitter</a>
                </dd>
                <dd class="goog" >
                  <a
                    onclick="gtag('event', 'homepage', {'event_category': 'Seller store','event_label': 'Share on','value': 'google+'});"
                    href="javascript:loadPage('googleplus', title, description)"
                    rel="nofollow">Google+</a>
                </dd>
                <dd class="vk" >
                  <a
                    onclick="gtag('event', 'homepage', {'event_category': 'Seller store','event_label': 'Share on','value': 'VK'});"
                    rel="nofollow"
                    href="javascript:loadPage('vk', title, description)">vk</a>
                </dd>
                <dd class="in" >
                  <a
                    onclick="gtag('event', 'homepage', {'event_category': 'Seller store','event_label': 'Share on','value': 'linkedin'});"
                    href="javascript:loadPage('linkedin', title, description)"
                    rel="nofollow">LinkedIn</a>
                </dd>
                <dd class="li9" >
                  <a
                    onclick="gtag('event', 'homepage', {'event_category': 'Seller store','event_label': 'Share on','value': 'google'});"
                    href="javascript:loadPage('google', title, description)"
                    rel="nofollow">Google</a>
                </dd>
                <dd class="li3">
                  <a
                    onclick="gtag('event', 'homepage', {'event_category': 'Seller store','event_label': 'Share on','value': 'kaboodle'});"
                    href="javascript:loadPage('kaboodle', title, description)"
                    rel="nofollow">Kaboodle</a>
                </dd>
                <dd class="li1">
                  <a
                    onclick="gtag('event', 'homepage', {'event_category': 'Seller store','event_label': 'Share on','value': 'delicious'});"
                    href="javascript:loadPage('del.icio.us', title, description)"
                    rel="nofollow">Delicious</a>
                </dd>
                <dd class="li6">
                  <a href="javascript:loadPage('stumbleupon', title, description)" rel="nofollow">StumbleUpon</a>
                </dd>
              </dl>
            </div>
          </div>
          <div class="store-info">

            <div class="store-title">

              <div class="store-name">
                <p>Apple Store</p>
                <p>
                  <a-icon type="heart" />
                  <a-icon type="heart" theme="filled" />
                </p>
              </div>
              <div class="store-add">
                <a-icon type="shop" style="font-size: 18px" />
                加入收藏店铺（666666）
              </div>
            </div>

            <div class="store-deal">
              <div class="store-data">
                <div class="store-data-left">
                  99.9%
                  <span>正面反馈</span>
                  999笔
                  <span>交易</span>
                </div>
                <div class="store-data-right">
                  <a-icon type="down" />
                </div>
              </div>
              <div class="onLineChat">
                <a-icon type="heart" style="font-size: 16px" />
                在线聊天
              </div>
            </div>

          </div>
        </div>
        <div class="store-right">
          <div class="store-input">
            <a-input placeholder="Basic usage" />
            <div class="store-input-icon" style="color: #fff">
              <a-icon type="search" style="font-size: 16px" />
              在vllshop
            </div>
            <div class="store-input-icon input-icon" style="color: #FF8223">在这家店</div>
          </div>
        </div>

      </div>
      <storeNavigation></storeNavigation>
    </div>
    <div class="storeClassify">
      <div class="store-classify">
        <div class="store-classify-left">
          <!--          <p>店铺分类</p>-->
          <!--          <ul>-->
          <!--            <li v-for="(item,index) in classify" :key="index">-->
          <!--              <a-icon type="right" class="li-icon" />-->
          <!--              {{ item }}-->
          <!--            </li>-->
          <!--          </ul>-->
          <!--          <div>更多的-->
          <!--            <a-icon type="down" style="margin-left: 8px" />-->
          <!--          </div>-->
          <storeClassify></storeClassify>
        </div>
        <div class="store-classify-right">
          <div class="store-classify-right-img">
            <img src="@/assets/images/ban-brand.jpg" alt="">
          </div>
          <div class="store-classify-right-title">
            店铺分类
            <p>Welcome to Our Store! We Are Offering High Quality Products with Reasonable Wholesale Price. All Products
              Are Supply from Factory Directly. Excellent Service and Fast Shipping</p>
          </div>
          <goodsClassify></goodsClassify>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import storeNavigation from '@/components/storeNavigation'
import goodsClassify from '@/components/goodsClassify'
import storeClassify from '@/components/storeClassify'

export default {
  components: { storeNavigation, goodsClassify, storeClassify },
  data() {
    return {
      classify: [
        '手机壳套',
        '手机充电器/数据线/移动电源',
        '手机配件',
        '电视佘/键盘/平板/智能电子',
        '蓝牙/耳机/音箱',
        '家庭/游戏/行业',
        '相机配件/自拍',
        '额外费用',
        '汽车配件',
        '工是/备件/SIM卡工县'
      ]
    }
  },
  methods: {
    onSearch(value) {
      console.log(value)
    }
  }
}
</script>
<style lang='scss' scoped>
.storeBox {
  width: 100%;
  background: #FFF2E5;
}

.store {
  padding-top: 20px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;

  .store-left {
    display: flex;

    .store-logo {
      height: 100px;
      width: 100px;
      line-height: 100px;
      font-family: "PingFang SC Bold";
      font-weight: bold;
      font-size: 24px;
      text-align: center;
      color: #bb3c72;
      background: #fff;
      margin-left: 20px;
    }

    .store-share {
      margin-left: 20px;
      color: #0364FF;
      margin-top: 8px;

      span {
        &:hover {
          color: #e71;
          text-decoration: underline;
          cursor: pointer;
        }
      }

      span:hover + #TopShareCon {
        display: block;
      }

      .store-share-icon {
        color: #707070;
        margin-left: 5px;
      }

      .clearfix:after {
        content: ".";
        display: block;
        height: 0;
        clear: both;
        visibility: hidden;
      }

      .topsharecon {
        width: 210px;
        background: #fff;
        -moz-box-shadow: 2px 2px 1px rgba(6, 0, 1, 0.13);
        -webkit-box-shadow: 2px 2px 1px rgb(6 0 1 / 13%);
        box-shadow: 2px 2px 1px rgb(6 0 1 / 13%);
        border: 1px solid #ddd;
        padding-bottom: 5px;
        position: absolute;
        display: none;

        &:hover {
          display: block;
        }

        dt {
          color: #d43434;
          padding: 5px 0 5px 15px;
          margin-bottom: 5px;
          font-weight: 700;
        }

        dd {
          float: left;
          width: 32%;
          margin: 0 0 4px 15px;
          _margin-left: 10px;
          padding: 3px 0 3px 21px;
          white-space: nowrap;
          background: url(https://www.dhresource.com/dhs/fob/img/store/storebg3.png) no-repeat;
          font-size: 11px;

          a {
            color: #039;
            text-decoration: none;

            &:hover {
              color: #e71;
              text-decoration: underline;
            }
          }
        }

        .li13 {
          background-position: 0 -945px;
        }

        .li12 {
          background-position: 0 -1181px;
        }

        .goog {
          background-position: 0 -1408px;
        }

        .vk {
          background-position: 0 -1350px;
        }

        .in {
          background-position: 0 -1380px;
        }

        .li9 {
          background-position: 0 -1112px;
        }

        .li3 {
          background-position: 0 -968px;
        }

        .li1 {
          background-position: 0 -920px;
        }

        .li6 {
          background-position: 0 -1040px;
        }
      }
    }

    .store-info {
      background: #fff2e6;
      margin-left: 18px;
      font-size: 12px;

      .store-title {
        font-family: "PingFang SC Bold";
        font-weight: bold;
        color: #0364ff;
        display: flex;

        .store-name {
          min-width: 100px;

          p {
            margin: 0;
          }
        }

        .store-add {
          color: #707070;
        }

      }

      .store-deal {
        background: #fff;
        min-width: 278px;
        min-height: 76px;
        padding: 0 11px;
        font-size: 12px;
        margin-top: 8px;

        .store-data {
          height: 26px;
          width: 100%;
          border-bottom: 1px solid #a7aec2;
          margin-bottom: 8px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .store-data-left {
            span {
              color: #B9B9B9;
              margin-right: 16px;
            }
          }
        }

        .onLineChat {
          color: #0364FF;
        }

      }

    }
  }

  .store-right {
    height: 30px;
    line-height: 30px;
    background: red;

    .store-input {
      display: flex;

      input {
        height: 30px;
        border: 1px solid #707070;
        border-right: none;
        border-radius: unset;
      }
    }

    .store-input-icon {
      width: 128px;
      text-align: center;
      background: #F3C91D;
      cursor: pointer;
    }

    .input-icon {
      background: #FFDFAE;
      height: 30px;
      border: 2px solid #F4DD50;
    }
  }
}

//店铺分类
.storeClassify {
  width: 100%;
  //height: 500px;
  background: #fff;

  .store-classify {
    margin-top: 32px;
    display: flex;

    .store-classify-left {
      min-width: 228px;
      //height: 347px;
      height: 100%;
      margin-right: 25px;
      background: #FFF2E5;
      border: 1px solid #ff8223;
    }

    .store-classify-right {
      flex: 1;

      .store-classify-right-img {
        height: 270px;
        padding-top: 17px;
        border: 1px solid #f1f1f1;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 16px;

        img {
          width: 740px;
          height: 250px;
        }
      }

      .store-classify-right-title {
        height: 82px;
        background: #fff;
        border: 1px solid #b9b9b9;
        padding: 14px;
        font-family: "PingFang SC Bold";
        font-weight: bold;
        font-size: 16px;
        text-align: left;
        color: #202124;
        margin-bottom: 16px;

        p {
          font-family: "PingFang SC";
          font-weight: 400;
          font-size: 12px;
          text-align: left;
          color: #202124;
          margin-top: 4px;
        }
      }
    }
  }
}
</style>
