<template>
  <div>
    <!--    <div class='classify-items' v-for='i of 2' :key='i'>-->
    <div class="classify-items">
      <!--      <h2>New Arrivals</h2>-->
      <div class="items" v-if="!isSwiper">
        <div class="item" v-for="(el,index) in list" :key="index">
          <img
            :src="el.picUrl"
            alt="">
          <p style="margin-bottom: 12px">
            Maybe Chair
            <span class="price">${{ el.price }}</span>
            <span class="text">/Piece</span>
          </p>
          <p style="margin-bottom: 6px">
            <span class="item-seller-coupon"><b>$3 off $2 +</b></span>
          </p>
          <p><span class="item-full-reduce">$400+ Save $4</span></p>
        </div>
      </div>
      <div class="goods-slideshow" v-else>
        <swiper
          ref="mySwiper"
          :options="swiperOptions"
          @some-swiper-event="callback"
        >
          <swiper-slide v-for="index of 20" :key="index">
            <swiperItem :styleIndex="swiperOptions.styleIndex"></swiperItem>
          </swiper-slide>

        </swiper>
        <div :class="['prev',swiperOptions.prevButton.slice(1)]" slot="button-prev">
          <a-icon type="left" style="color: #fff" />
        </div>
        <div :class="['next',swiperOptions.nextButton.slice(1)]" slot="button-next">
          <a-icon type="right" style="color: #fff" />
        </div>
      </div>
    </div>
    <div class="classify-bottom" v-if="!isSwiper">See All Prouducls
      <a-icon type="right" />
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import swiperItem from '@/components/swiperItem/index'
import { Navigation, Scrollbar } from 'swiper/js/swiper.esm'
import { Pagination } from 'ant-design-vue'

export default {
  name: 'ClassifyItem',
  components: { swiper, swiperSlide, swiperItem },
  props: {
    isSwiper: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      list: [
        {
          picUrl: 'https://www.dhresource.com/webp/m/300x300/f2/albu/g11/M01/85/40/rBNaFV8g3F6AL1IfAAY15WEK0NQ317.jpg',
          price: '00.00'
        },
        {
          picUrl: 'https://www.dhresource.com/webp/m/300x300/f2/albu/g11/M01/85/40/rBNaFV8g3F6AL1IfAAY15WEK0NQ317.jpg',
          price: '00.00'
        },
        {
          picUrl: 'https://www.dhresource.com/webp/m/300x300/f2/albu/g11/M01/85/40/rBNaFV8g3F6AL1IfAAY15WEK0NQ317.jpg',
          price: '00.00'
        },
        {
          picUrl: 'https://www.dhresource.com/webp/m/300x300/f2/albu/g11/M01/85/40/rBNaFV8g3F6AL1IfAAY15WEK0NQ317.jpg',
          price: '00.00'
        }
      ],
      // 轮播图
      swiperOptions: {
        modules: [Navigation, Pagination, Scrollbar],
        slidesPerView: 'auto',
        // height: 100,
        // width: '268',
        // 水平滚动
        direction: 'horizontal',
        // 设置分页器
        pagination: {
          el: '.swiper-pagination',
          // 设置点击可切换
          clickable: true
        },
        // 设置前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        // 设置自动轮播
        autoplay: false,
        // 设置轮播可循环
        loop: false
      },
      value: 5
    }
  },
  methods: {
    callback() {
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep .swiper-button-next:after, .swiper-container-rtl .swiper-button-prev:after {
  content: '';
}

::v-deep .swiper-button-prev:after, .swiper-container-rtl .swiper-button-next:after {
  content: '';
}

.classify-items {
  //padding: 32px 0px;
  //border-bottom: 1px solid #a7aec2;

  h2 {
    font-family: "PingFang SC Bold",serif;
    font-weight: bold;
    font-size: 22px;
    text-indent: 14px;
    color: #202124;
  }
}

.items {
  display: flex;
  justify-content: space-between;

  .item {
    width: 220px;

    img {
      //width: 100%;
      width: 220px;
      margin-bottom: 15px;
    }

    p {
      font-family: "Roboto-Regular ",serif;
      font-weight: 400;
      font-size: 12px;
      text-align: left;
      color: #0a0d10;
      margin: 0;

      .price {
        font-family: "Roboto-Regular ",serif;
        font-weight: 400;
        font-size: 18px;
        text-align: right;
        color: #0a0d10;
      }

      .text {
        font-family: "Roboto-Regular ",serif;
        font-weight: 400;
        font-size: 12px;
        text-align: left;
        color: #707070;
      }
    }
  }
}

.classify-bottom {
  height: 34px;
  background: #f1f1f1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "PingFang SC",serif;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
  color: #202124;
  cursor: pointer;
  border-top: 1px solid #a7aec2;

  &:hover {
    color: #e71;
    text-decoration: underline;
  }
}

.item-seller-coupon {
  position: relative;
  display: inline-block;
  margin: 0 6px 0 6px;
  max-width: calc(100% - 12px);
  height: 18px;
  line-height: 18px;
  color: #080;
  font-size: 11px;
  border: 0 none;
  border-radius: 0;
  background: #bfea7b;
}

.item-seller-coupon::before, .item-seller-coupon::after {
  content: '';
  position: absolute;
  top: 0;
  width: 6px;
  height: 18px;
  background: radial-gradient(transparent 0, transparent 4px, #bfea7b 4px);
  background-size: 18px 18px;
}

.item-seller-coupon::before {
  left: -6px;
  border-radius: 2px 0 0 2px;
  background-position: -10px 0;
}

.item-seller-coupon::after {
  right: -6px;
  border-radius: 0 2px 2px 0;
  background-position: -2px 0;
}

.item-seller-coupon b {
  overflow: hidden;
  display: inline-block;
  max-width: 100%;
  height: 18px;
  font-weight: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.item-full-reduce {
  overflow: hidden;
  display: inline-block;
  padding: 0 6px;
  max-width: 100%;
  height: 18px;
  line-height: 18px;
  font-size: 11px;
  color: #ff8000;
  text-align: center;
  border-radius: 2px;
  background-color: #ffea9b;
  white-space: nowrap;
  text-overflow: ellipsis;
  box-sizing: border-box;
}

.goods-slideshow {
  //width: 100px;
  width: 100%;
  //height: 528px;
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  //padding: 0px 28px;
}

.swiper-container {
  //height: 276px;
  width: 100%;
  background: #fff;
}

.swiper-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100% - 56px) !important;
}

.swiper-slide {
  //line-height: 132px;
  background: #fff;
  padding-bottom: 10px;
  //width: 300px;
  //width: calc(100% - 56px) !important;
  //width: 100% !important;
  //img {
  //  width: 100px;
  //  height: 100px;
  //}
  //margin-right: 16px;
  //width: 268px !important;
  //width: 185px !important;
  width: 220px;
  //height: 276px;
}

.prev, .next {
  position: absolute;
  width: 28px;
  height: 70px;
  line-height: 70px;
  background: #2e2828;
  opacity: 0.5;
  text-align: center;
  z-index: 999;
  cursor: pointer;
}

.prev {
  left: 0px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.next {
  right: 0px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

@media (max-width: $large) {
  .swiper-slide {
    width: 185px;
  }
}

@media (max-width: $small) {
  .swiper-slide {
    width: 172px;
  }
}
</style>
