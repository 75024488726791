<template>
  <div class="store-classify-box">
    <p class="title">店铺分类</p>
    <!--    v-model="activeKey"-->
    <a-collapse accordion :bordered="false" @change="isChange">
      <a-collapse-panel
        v-for="(item,i) in classify"
        :key="i"
        :header="item">
        <p style="margin: 0">{{ text }}</p>
      </a-collapse-panel>
    </a-collapse>
    <!--    <ul>-->
    <!--      <li v-for="(item,index) in classify" :key="index">-->
    <!--        <a-icon type="right" class="li-icon"/>-->
    <!--        {{ item }}-->
    <!--      </li>-->
    <!--    </ul>-->
    <div class="bottom-text">更多的
      <a-icon type="down" style="margin-left: 8px" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'StoreClassify',
  data() {
    return {
      classify: [
        '手机壳套',
        '手机充电器/移动电源',
        '手机配件',
        '键盘/平板/智能电子',
        '蓝牙/耳机/音箱',
        '家庭/游戏/行业',
        '相机配件/自拍',
        '额外费用',
        '汽车配件',
        '工是/备件/SIM卡工县'
      ],
      text: `666666`,
      activeKey: [],
      // 轮播图
      swiperOptions: {
        slidesPerView: 'auto',
        // height: 100,
        // width: '268',
        // 水平滚动
        direction: 'horizontal',
        // 设置分页器
        pagination: {
          el: '.swiper-pagination',
          // 设置点击可切换
          clickable: true
        },
        // 设置前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        // 设置自动轮播
        autoplay: false,
        // 设置轮播可循环
        loop: false
      },
    }
  },
  watch: {
    activeKey(key) {
      console.log('watch', key)
    }
  },
  methods: {
    isChange(keys) {
      console.log('isChange', keys)
    }
  }
}
</script>

<style lang='scss' scoped>
::v-deep .ant-collapse-borderless {
  background-color: unset;
}

::v-deep .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 4px 0 4px 26px;
}

::v-deep .ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  left: 0;
}

::v-deep .ant-collapse-borderless > .ant-collapse-item {
  border-bottom: none;
}

::v-deep .ant-collapse-content > .ant-collapse-content-box {
  padding: 0 !important;
  text-indent: 26px;
}

::v-deep .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: #707070;
}

.store-classify-box {
  width: 100%;
  padding: 12px;

  .title {
    font-family: "PingFang SC Bold";
    font-weight: bold;
    font-size: 14px;
    text-align: left;
    color: #202124;
    margin-bottom: 30px;
  }

  ul {
    margin: 0;
    padding: 0;

    li {
      height: 25px;
      line-height: 25px;
      list-style: none;
      font-family: "PingFang SC";
      font-weight: 400;
      font-size: 12px;
      text-align: left;
      color: #707070;

      .li-icon {
        margin-right: 10px;
      }
    }
  }

  .bottom-text {
    font-family: "PingFang SC";
    font-weight: 400;
    font-size: 12px;
    text-align: right;
    color: #0364ff;
  }
}

</style>
